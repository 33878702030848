<template>
  <div style="width:100%; height: 100%;overflow:scroll;">
    <div class="ls">
      <div class="eti">欠款顾客列表
      </div>
    </div>
      <div style="margin: 15px 15px 30px 15px;width: 300px">
        <el-input placeholder="姓名 / 手机号" v-model="mpName" size="mini" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="getMemberArrearsList()"></el-button>
        </el-input>
      </div>
      <div class="del-dialog-cnt">
        <el-table :data="list" size="small" style="width: 100%;overflow: auto;">
          <el-table-column prop="memberName"  label="顾客姓名" />
          <el-table-column prop="phone"  label="手机号码" />
          <el-table-column prop="amount"  label="欠款总金额" />
          <el-table-column prop="createTime" label="操作">
            <template slot-scope="scope">
              <el-button size="small" type="text" @click="toDetailed(scope.row)" >查看明细</el-button>
<!--              <el-button size="small" type="text" @click="toRepayment(scope.row)" >还款</el-button>-->
              <el-button size="small" type="text" @click="toRecharge(scope.row)" >充值还款</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :pager-count="7"
            layout="total, prev, pager, next"
            :total="pagination.count"
            :page-count="pagination.pageCount"
            :current-page="pagination.pageNumber"
          ></el-pagination>
        </div>
      </div>

    <!-- 欠款明细 -->
    <el-dialog title="欠款明细" :visible.sync="mxVisible" width="800px" center>
      <div>
        <el-table :data="mxList" size="small" style="width: 100%;overflow: auto;" height="400px">
          <el-table-column prop="orderNo" label="订单号" />
          <el-table-column prop="createTime" label="日期" />
          <el-table-column prop="totalPrice" label="订单金额" />
          <el-table-column prop="amount" label="欠款金额" />
        </el-table>
      </div>
    </el-dialog>


    <TabRecharge
      v-if="payVisible"
      :payVisible="payVisible"
      :assetsItem="assetsItem"
      @closeRecharge="closeRecharge"
      @successRecharge="successRecharge"
    />
    <TabRepayment
      v-if="zjVisible"
      :payVisible="zjVisible"
      :assetsItem="assetsItem"
      @fanhui="fanhui"
      @closePay="closePay"
    />
  </div>
</template>

<script>
  import TabRecharge from "./recharge";
  import TabRepayment from "./repayment";
  import {getToken} from "../../../utils/auth";

  export default {
    name: "haikuan",
    components: {
      TabRecharge,
      TabRepayment,
    },
    data() {
      return {
        mpName: "",
        memberId: "",

        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

        mxList:[],
        mxVisible:false,

        payVisible: false,
        zjVisible: false,
        assetsItem: {},
      };
    },
    async created() {
      this.getMemberArrearsList()
    },

    methods: {
      handleCurrentChange(page) {
        this.getMemberArrearsList(page)
      },
      async toDetailed(item){
      this.mxVisible=true
        let data = {
          memberId:item.memberId,
          token:getToken(),
        };
        let res = await this.$get("/sw/getMaInfos", data);
        this.mxList = res.list;
      },

      closeRecharge() {
        this.payVisible = false
      },
      successRecharge() {
        this.payVisible = false
        this.getMemberArrearsList()
      },

      fanhui() {
        this.zjVisible = false
      },
      closePay() {
        this.zjVisible = false
        this.getMemberArrearsList()
      },


      toRepayment(item) {
        this.assetsItem=item
        this.zjVisible = true;
      },
      toRecharge(item) {
        this.assetsItem=item
        this.payVisible = true;
      },

      async getMemberArrearsList(page) {
        let currentPage = page || 1;
        let data = {
          mpName: this.mpName,
          token:getToken(),
          currentPage
        };
        let res = await this.$get("/sw/getMemberArrearsList", data);
        this.list = res.dataSet.list;
        this.pagination = {
          count: res.dataSet.count,
          pageNumber: res.dataSet.pageNumber,
          pageSize: res.dataSet.pageSize,
          pageCount: res.dataSet.pages
        }
      },
    },
  };
</script>


<style lang="scss" scoped>
  .ls {
    width: 100%;
    padding: 0 15px;
    background: #fff;
    border-bottom: #e4e4e4 solid 1px;
  }

  .ls .eti {
    display: block;
    color: #1e1e1e;
    font-size: 14px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
  }
  .del-dialog-cnt{
    width: 95%;
    margin: auto;
  }

</style>
